<template>
  <div>
    <div id="recaptcha-container" />
    <estimate />
  </div>
</template>

<script>

const Estimate = () => ({
  component: import('@/components/Estimate'),
  loading: import('@/components/Loader')
})

export default {
  name: 'ClientHome',
  components: {
    Estimate
  }
}
</script>
